import React, { useState } from "react";

const items = [
  "Wipes/scratches face with whole finger",
  "Deal finder",
  "Stylish",
  "Adds style to me",
  "Adds the 'S' to things like Mikuni's or Robins Egg blue",
  "Patient for me",
  "Remembers my birthday and all sorts of special occasions",
  "Talked me into getting my new backpack",
  "Goes places with me",
  "Went to Reno with me after my job interview",
  "The way she says 'Full house'",
  "Likes cones",
  "Playing spelling bee and other games",
  "She never forgets to moisturize",
  "Uses the running board to get into the van",
  "Humors my interests",
  "Humors my humor",
  "Babies her plants",
  "Loves all pets",
  "Asian Pancreas",
  "Caffeine fiend",
  "Always converges when singing a round or sleeping in a big bed",
  "Any available food is to be eaten",
];
items.push("Helps me fix El Macero");
items.push({
  title: "Pet Peeves",
  list: [
    "Heating edamame herself",
    "Mixing salads herself",
    "Tipping #asianpancreas",
  ],
});
items.push({
  title: "Bad smells list",
  list: ["Homeless", "B.O.", "Chemical", "Milk"],
});
items.push('"Live Nature!"');
items.push("Marriage?");

export default function Michelle() {
  const [inputValue, setInputValue] = useState("");
  const [chellum, setChellum] = useState(false);
  const [listMax, setListMax] = useState(0);
  const [inputEntered, setInputEntered] = useState(false);

  const onChangeHandler = (e) => {
    setInputEntered(true);
    if (e.target.value === "Da Chellum") {
      setChellum(true);
    }
    setInputValue(e.target.value);
  };

  const addRow = (item, rows) => {
    const newRows = rows.slice();

    if (typeof item === "string" || item instanceof String) {
      newRows.push(<li key={newRows.length}>{item}</li>);
    } else {
      // else it's an object
      const { title, list } = item;
      const subItems = [];
      for (let i = 0; i < list.length; i += 1) {
        const current = list[i];
        subItems.push(<li key={newRows.length + "-" + i}>{current}</li>);
      }
      newRows.push(
        <li key={newRows.length}>
          {title}
          {<ul>{subItems}</ul>}
        </li>
      );
    }

    return newRows;
  };

  const handleAddRow = () => {
    if (listMax < items.length) {
      setListMax(listMax + 1);
    }
  };

  const createRows = () => {
    let rows = [];

    for (let i = 0; i < listMax; i += 1) {
      rows = addRow(items[i], rows);
    }

    return rows;
  };

  return (
    <main style={{ padding: "1rem 0", margin: "20px" }}>
      <div> What does DC stand for?</div>

      <span>
        <div>Your Answer: {inputValue}</div>
        <form>
          <label>
            Name:
            <input
              type='text'
              name='name'
              onChange={onChangeHandler}
              value={inputValue}
              autoFocus='autofocus'
              disabled={chellum}
            />
          </label>
        </form>
        {inputEntered && <div>{chellum ? "How you knew!" : "Not Quite"}</div>}

        {chellum && (
          <span>
            <br />
            <div>
              <div>25 Things I like about her:</div>
              <ul>{createRows()}</ul>
            </div>
            <button
              type='button'
              onClick={handleAddRow}
              autoFocus='autofocus'
              disabled={listMax === items.length}
            >
              See Another
            </button>
          </span>
        )}
      </span>
    </main>
  );
}
