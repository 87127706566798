import { Outlet, Link } from "react-router-dom";

export default function App() {
  return (
    <div>
      <h1>Shawn Ranck</h1>

      <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem",
        }}
      >
      <Link to='/'>Home</Link>{" | "}
        <a
          href='https://barkerlab.ucdavis.edu/people/members/#:~:text=sranck%40ucdavis.edu)-,Shawn%20Ranck,-is%20a%20software'
          target='_blank'
          rel='noreferrer'
        >
          Work
        </a>
        {" | "}
        <a href='https://github.com/smranck' target='_blank' rel='noreferrer'>
          Code
        </a>
        {" | "}
        <Link to='/michelle'>Love</Link>{" "}
      </nav>
      <Outlet />
    </div>
  );
}
