import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Michelle from "./components/michelle";

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />}>
        <Route path='michelle' element={<Michelle />} />
      </Route>
      <Route
        path='*'
        element={
          <main style={{ padding: "1rem" }}>
            <p>That's not a real route</p>
          </main>
        }
      />
    </Routes>
  </BrowserRouter>,
  rootElement
);

// @shawn we'll leave this here for now
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
